<div class="section-wrapper">
  <div class="section-content">
    <mat-icon svgIcon="edu:ic-quote"></mat-icon>
    <div class="heading-2 color-dark-blue text-align-center" i18n="Reviews heading">Atsauksmes</div>
    <edu-slider></edu-slider>
    <div class="body-1 color-dark-grey text-align-center" i18n="Reviews body">
      Vairāk atsauksmes vari atrast mūsu Instagram profilā - sadaļā "Atsauksmes" ⭐️
    </div>
    <a
      mat-flat-button
      color="primary"
      href="https://www.instagram.com/eduhelp_lv"
    >
      <span i18n="Reviews button title">Skatīt visas atsauksmes</span>
      <span class="ml-10">&#8594;</span>
    </a>
  </div>
</div>

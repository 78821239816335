<div class="nav-wrapper" #navigation>
  <div class="upper">

    <div class="nav-left">
      <a (click)="scrollToIndex(0)">
        <img class="logo" [src]="logoPath + logoName">
      </a>
    </div>

    <div class="nav-right">

      <div class="contacts">
        <div class="contact">
          <mat-icon>email</mat-icon>
          <a class="heading-4 link-2" href="mailto:info@eduhelp.lv">info&#64;eduhelp.lv</a>
        </div>

        <div class="contact">
          <mat-icon>local_phone</mat-icon>
          <a class="heading-4 link-2" href="tel:+371 26936857">+371 26936857</a>
        </div>
      </div>

      <div class="splitter"></div>

      <div class="lang-button" [matMenuTriggerFor]="menu">
        <mat-icon [svgIcon]="langIcon"></mat-icon>
        <mat-icon class="icon-expand">expand_more</mat-icon>
      </div>

      <a mat-flat-button class="small" color="primary" [routerLink]="'order'" i18n="@@orderButtonTitle">Nosūtīt pieteikumu</a>

    </div>

    <div class="burger">
      <button mat-icon-button
        (click)="openMobileMenu()">
        <mat-icon>menu</mat-icon>
      </button>
    </div>

  </div>
  <div *ngIf="!hideTabs" class="lower">
    <div class="tabs">
      <div *ngFor="let tab of tabs; let i = index" [class.hidden]="i === 7"
           #tabs
           class="tab color-dark-blue body-2"
           (click)="scrollToIndex(i)">
        {{tab}}
      </div>
      <span class="tab-slider"
            [style.left.px]="tabSliderLeft"
            [style.width.px]="tabSliderWidth"
      ></span>
    </div>
  </div>
</div>

<mat-menu #menu="matMenu">
  <a class="menu-item" mat-menu-item href="/lv/">
    <div class="menu-link body-2 color-dark-grey">
      <mat-icon svgIcon="edu:ic-lv">lv</mat-icon>Latviešu
    </div>
  </a>
  <a class="menu-item" mat-menu-item href="/ru/">
    <div class="menu-link body-2 color-dark-grey">
      <mat-icon svgIcon="edu:ic-ru">ru</mat-icon>Русский
    </div>
  </a>
</mat-menu>

<div class="section-wrapper">
  <div class="section-content">
    <div class="action-wrapper">

      <div class="message">
        <div class="heading-4 color-white" i18n="Action heading">
          Neatliec darba izpildīšanu uz pēdējo dienu
        </div>
        <div class="body-2 color-extra-light-grey" i18n="Action body">
          Nosūti pieteikumu un saņem atbildi ar piedāvājumu par cenu un izpildīšanas nosacījumiem stundas laikā.
        </div>
      </div>

      <a
        mat-flat-button
        color="primary"
        [routerLink]="'./order'">
        <span i18n="@@orderButtonTitle">Nosūtīt pieteikumu</span>
      </a>
    </div>
  </div>
</div>

<div class="section-wrapper">
  <div class="section-content">
    <mat-icon class="illustration" svgIcon="edu:ill-chat"></mat-icon>

    <div class="heading-2 text-align-center color-dark-blue" i18n="Questions heading">
      Vēl kādi jautājumi?
    </div>
    <div class="body-1 text-align-center mt-30" i18n="Questions body">
      Sazinies ar mums jebkurā laikā 👩‍💻
    </div>

    <div class="questions-content">
      <div class="left-section">
        <div class="heading-4 color-dark-blue" i18n="Questions form heading">
          Aizpildi un nosūti šo formu:
        </div>

        <form #ngForm="ngForm" [formGroup]="questionForm" (ngSubmit)="onFormSubmit()">
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput
                   required
                   placeholder="andrisliepa@gmail.com"
                   formControlName="email">
            <mat-error *ngIf="questionForm.controls.email.invalid">{{getEmailErrorMessage}}</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label i18n="Your question field label">Jūsu jautājumus</mat-label>
            <textarea matInput
                      required
                      [placeholder]="questionPlaceholder"
                      formControlName="question"></textarea>
            <mat-hint align="end">{{questionForm.controls.question.value?.length}} / 256</mat-hint>
            <mat-error *ngIf="questionForm.controls.question.invalid">{{getQuestionErrorMessage}}</mat-error>
          </mat-form-field>

          <button color="primary" mat-flat-button>
            <span i18n="@@sendFormButtonTitle">Nosūtīt</span>
            <span class="ml-10">&#8594;</span>
          </button>
        </form>
      </div>

      <div class="right-section">
        <div class="heading-4 color-dark-blue" i18n="Question contacts heading">Uzdod savu jautājumu uz tiešo:</div>

        <div class="contacts">
          <div class="contact heading-3">
            <mat-icon>email</mat-icon>
            <a class="link-2" href="mailto:info@eduhelp.lv">info&#64;eduhelp.lv</a>
          </div>
          <div class="contact heading-3">
            <mat-icon>local_phone</mat-icon>
            <a class="link-2" href="tel:+371 26936857">+371 26936857</a>
          </div>
          <a mat-stroked-button
             href="https://wa.me/37126936857">
            <mat-icon svgIcon="edu:ic-wp"></mat-icon>
            <span class="ml-10" i18n="Whatsapp button title@@whatsappButtonTitle">Uzrakstīt Whatsapp</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

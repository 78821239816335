<div class="section-wrapper main">
  <div class="section-content">
    <div class="intro-wrapper">
      <div class="upper">
        <div class="main-section">

          <div class="heading-1 color-dark-blue">
            <span i18n="Intro heading static">Kvalitatīva palīdzība ar</span>
            <div class="typewriter">
              <div class="text" [innerHTML]="typewriterText"></div>
            </div>
          </div>
          <div class="body-1 color-dark-grey" i18n="Intro body">
            Grūta tēma, vai nav laika darbu izpildīšanai? Mēs Tev palīdzēsim!<br> Nosūtī pieteikumu un saņem garantēto rezultātu! 🙌
          </div>
        </div>

        <mat-icon class="illustration" svgIcon="edu:ill-study-work"></mat-icon>

        <div class="buttons">
          <a class="primary-button"
             mat-flat-button
             color="primary"
             routerLink="order"
             i18n="Order button title@@orderButtonTitle">Nosūtīt pieteikumu</a>

          <a mat-stroked-button
             href="https://wa.me/37126936857">
            <mat-icon svgIcon="edu:ic-wp"></mat-icon>
            <span class="ml-10" i18n="Whatsapp button title@@whatsappButtonTitle">Uzrakstīt Whatsapp</span>
          </a>
        </div>

        <div class="costs-form-wrapper">
          <div class="heading-3 color-dark-blue" i18n="Costs form heading">Uzzini izmaksas:</div>

          <form #ngForm="ngForm" [formGroup]="questionForm" (ngSubmit)="onFormSubmit()">
            <mat-form-field appearance="outline">
              <mat-label i18n="Form field work description label@@formFieldWorkDescLabel">Darba apraksts</mat-label>
              <input matInput
                     required
                     [placeholder]="workDescriptionPlaceholder"
                     formControlName="question">
              <mat-hint align="end">{{questionForm.controls.question.value?.length}} / 256</mat-hint>
              <mat-error *ngIf="questionForm.controls.question.invalid">{{getQuestionErrorMessage}}</mat-error>
            </mat-form-field>

<!--            <mat-form-field appearance="outline">-->
<!--              <mat-label>Email</mat-label>-->
<!--              <input matInput-->
<!--                     placeholder="name@example.com"-->
<!--                     required-->
<!--                     formControlName="email">-->
<!--              <mat-error *ngIf="questionForm.controls.email.invalid">{{getEmailErrorMessage}}</mat-error>-->
<!--            </mat-form-field>-->

            <mat-form-field appearance="outline">
              <mat-label i18n="Form field telephone label@@formFieldTelephoneLabel">Telefona numurs</mat-label>
              <input matInput
                     required
                     placeholder="22222222"
                     type="number"
                     formControlName="number">
              <mat-error *ngIf="questionForm.controls.number.invalid">{{getNumberErrorMessage}}</mat-error>
            </mat-form-field>

            <button color="primary"
                    mat-flat-button>
              <mat-spinner *ngIf="formBusy" [strokeWidth]="3" [diameter]="36"></mat-spinner>
              <ng-container *ngIf="!formBusy">
                <span i18n="Find costs button title">Uzzināt izmaksas</span>
                <span class="ml-10">&#8594;</span>
              </ng-container>
            </button>
          </form>

          <div class="body-3 color-dark-grey">
            <span i18n="Costs form info">Vēlies sniegt pilnu aprakstu?</span>&nbsp;
            <a class="link" routerLink="order" i18n="@@orderButtonTitle">Nosūtīt pieteikumu</a>
          </div>
        </div>

<!--        <div class="tutoring-wrapper">-->
<!--          <div class="left-section">-->
<!--            <mat-icon svgIcon="edu:ill-tutoring"></mat-icon>-->
<!--          </div>-->
<!--          <div class="right-section">-->
<!--            <div class="heading-4 color-white" i18n="Intro tutoring title">-->
<!--              Vēlies izprast mācību vielu pats, lai sagatavotos kontroldarbam vai uzlabotu savas zināšanas? Izmēģini-->
<!--              mūsu online ampācību pakalpojumu!-->
<!--            </div>-->
<!--            <a class="small"-->
<!--               mat-flat-button-->
<!--               color="primary"-->
<!--               routerLink="tutoring"-->
<!--               i18n="Tutoring order button title@@tutoringOrderButtonTitle">Pieteikties apmācībām</a>-->

<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</div>

<div class="section-wrapper bg-color-light-blue">
  <div class="section-content">
    <div class="advantages-wrapper">
      <div class="advantages-box">
        <div *ngFor="let advantage of advantages" class="advantage">
          <div class="heading-2 color-blue">{{advantage.number}}</div>
          <div class="body-1 color-dark-blue">{{advantage.name}}</div>
        </div>
      </div>
    </div>
  </div>
</div>

import "@angular/localize/init"

export const tabTitles = [
  $localize`:Navigation 1 title:Galvenā`,
  $localize`:Navigation 2 title:Atsauksmes`,
  $localize`:Navigation 3 title:Priekšrocības`,
  $localize`:Navigation 4 title:Process`,
  $localize`:Navigation 5 title:Sociālie tīkli`,
  $localize`:Navigation 6 title:Par mums`,
  $localize`:Navigation 7 title:Komanda`,
  '',
];

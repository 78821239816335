<div class="page-wrapper">
  <div #section>
    <edu-intro2></edu-intro2>
  </div>

  <div #section>
    <edu-reviews></edu-reviews>
  </div>

  <div #section>
    <edu-services></edu-services>
  </div>

  <div #section>
    <edu-work></edu-work>
  </div>

  <div #section>
    <edu-social-medias></edu-social-medias>
  </div>

  <div #section>
    <edu-about></edu-about>
  </div>

  <div #section>
    <edu-join-team></edu-join-team>
  </div>

  <div #section>
    <edu-questions></edu-questions>
  </div>

  <edu-action></edu-action>

</div>

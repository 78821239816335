<div class="page-wrapper">
  <div class="section-wrapper">
    <div class="section-content">

      <div class="terms">
        <div class="heading-2 color-dark-blue" i18n="@@terms">Privātuma politika</div>
        <div class="body-1 color-dark-grey">
          Šī privātuma politika attiecas uz vietni eduhelp.lv un uz pasūtītāju
          personas datiem, kas tiek apkopoti vietnes izmantošanas un pieteikuma nosūtīšanas laikā. Mēs cienām un
          rūpējamies par Jūsu privātumu un aizsargājam Jūsu personas datus saskaņā ar Latvijas Republikas likumiem, kā
          arī ar attiecīgajiem Eiropas Savienības tiesību aktiem.
        </div>

        <div class="heading-3 color-dark-blue">Piekrišana privātuma politikai</div>
        <div class="body-2 color-dark-grey">
          Izmantojot vietni eduhelp.lv un/vai pasūtot pakalpojumu, Jūs piekrītat, ka izlasījāt un sapratāt šo
          privātuma politiku un piekrītat tās noteikumiem. Jūs arī apņematies ievērot šo politiku, un piekritāt, ka Jūsu
          personas dati tiks izmantoti un apstrādāti atbilstoši šīs politikas noteikumiem. Ja Jūs nepiekrītat privātuma
          politikai, lūdzu, neizmantojiet mūsu vietnes pakalpojumus un neveiciet pasūtījumus. Jūs arī piekrītat, ka varat izmantot
          savas tiesības, kuras ir aprakstītas šajā politikā, un, varat pieprasīt savu personas datu piekļuvi, labošanu
          vai dzēšanu.
        </div>

        <div class="heading-3 color-dark-blue">Personas datu aizsardzība</div>
        <div class="body-2 color-dark-grey">
          Mēs esam atbildīgi par Jūsu personas datu aizsardzību un uzturam atbilstošus tehniskos un fiziskos drošības
          pasākumus, lai aizsargātu Jūsu personas datus no nejaušas, nelikumīgas vai neautorizētas piekļuves,
          izmantošanas un citiem pretlikumīgiem izmantošanas veidiem.
        </div>

        <div class="heading-3 color-dark-blue">Personas datu izmantošanas nolūki</div>
        <div class="body-2 color-dark-grey">
          Personas dati tiek apkopoti sekojošos nolūkos:
          <ul>
            <li>
              Klientu apkalpošana: klientu apkalpošanas nodrošināšanai un piemērotai atbildēšanai uz klientu
              jautājumiem.
            </li>
            <li>
              Mārketinga īstenošana: mārketinga kampaņas veikšanai, piemēram, izsūtot personīgus piedāvājumus un
              reklāmas klientiem, kuri ir izrādījuši interesi par noteiktiem pakalpojumiem.
            </li>
            <li>
              Uzņēmējdarbības plānošana: uzņēmējdarbības attīstības plānošana un prognozēšana, ka arī finanšu un citu
              veidu analīzes veikšana.
            </li>
            <li>
              Drošības pasākumi: datu drošības nodrošināšana un to neautorizētās piekļuves novēršana.
            </li>
          </ul>
        </div>

        <div class="heading-3 color-dark-blue">Ziņojumi</div>
        <div class="body-2 color-dark-grey">Mēs varam izmantot Jūsu personas datus, lai nosūtītu Jums mārketinga
          ziņojumus par mūsu pakalpojumiem, kā arī citiem piedāvājumiem, kas varētu Jūs interesēt. Šie ziņojumi var
          ietvert informāciju par aktuālajiem piedāvājumiem, akcijām un citiem pakalpojumiem. Jums ir tiesības
          atteikties no mārketinga ziņojumu saņemšanas jebkurā laikā, un mēs esam apņēmušies nodrošināt šo vēlēšanos.
        </div>

        <div class="heading-3 color-dark-blue">Jūsu tiesības</div>
        <div class="body-2 color-dark-grey">
          Jūsu tiesības privātuma politikā ietver:
          <ul>
            <li>
              Tiesības uzzināt, kādus personas datus mēs saglabājam un kā tie tiek izmantoti.
            </li>
            <li>
              Tiesības pieprasīt skatīt un rediģēt Jūsu personas datus.
            </li>
            <li>
              Tiesības pieprasīt Jūsu personas datu izdzēšanu.
            </li>
            <li>
              Tiesības saņemt informāciju par drošības pasākumiem, kas tiek izmantoti, lai aizsargātu Jūsu personas
              datus.
            </li>
            <li>
              Tiesības pieprasīt apstiprinājumu par to, vai mēs saglabājam un izmantojam Jūsu personas datus.
            </li>
          </ul>
        </div>

        <div class="heading-3 color-dark-blue">Sīkdatņu izmantošana</div>
        <div class="body-2 color-dark-grey">
          Vietne eduhelp.lv izmanto sīkdatnes, lai uzlabotu lietotāja pieredzi. Sīkdatnes ir nelieli teksta faili, kas
          tiek saglabāti Jūsu datorā vai mobilajā ierīcē, kad Jūs apmeklējat vietni. Jūs varat vadīt sīkdatņu
          izmantošanu, mainot Jūsu pārlūkprogrammas iestatījumus. Lūdzu, ņemiet vērā, ka, ja atspējosiet sīkdatnes,
          dažas no vietnes funkcijām var darboties nepareizi.
          Mēs izmantojam Google Analytics, lai analizētu, kā lietotāji izmanto vietni eduhelp.lv. Google Analytics
          izmanto sīkdatnes, lai sniegtu mums informāciju par vietnes apmeklējumiem un lietotāju uzvedību. Jūs varat
          atspējot Google Analytics sīkdatnes, lejupielādējot un instalējot Google Analytics.
        </div>

        <div class="heading-3 color-dark-blue">Izmaiņas privātuma politikā</div>
        <div class="body-2 color-dark-grey">
          Mēs varam mainīt privātuma politiku, lai tā atbilstu jaunajiem likumiem un mūsu vajadzībām. Izmaiņas stāsies spēkā
          no tā brīža, kad tās tiks publicētas vietnē eduhelp.lv. Tiek uzskatīts, ka esat piekrituši jebkādām izmaiņām, ja
          Jūs turpināt izmantot mūsu pakalpojumus pēc izmaiņu publicēšanas. Ja Jūs nepiekrītat izmaiņām, Jums ir
          tiesības pārtraukt izmantot mūsu pakalpojumus un vietni.
        </div>

        <div class="heading-3 color-dark-blue">Atsauksmes un kontaktinformācija</div>
        <div class="body-2 color-dark-grey">
          Mēs novērtēsim jebkādas atsauksmes par privātuma politiku un būsim pateicīgi saņemt jebkādas sūdzības vai
          jautājumus par to. Jūs varat sūtīt atsauksmes vai jautājumus par privātuma politiku uz e-pastu info&#64;eduhelp.lv
          vai aizpildot vietnē pieejamo jautājumu formu.
        </div>

      </div>
    </div>
  </div>
</div>

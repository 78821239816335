<!--<edu-info-block></edu-info-block>-->
<edu-navigation></edu-navigation>

<div class="global-content">
  <div>
    <router-outlet></router-outlet>
  </div>
  <edu-footer></edu-footer>
</div>



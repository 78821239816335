<div class="page-wrapper">
  <div class="section-wrapper">
    <div class="section-content">

      <div class="make-order-wrapper">
        <div class="heading-1 color-dark-blue text-align-center" i18n="Order heading">Pasūti darba izpildīšanu</div>
        <mat-icon class="arrow stroke-blue" svgIcon="edu:ic-arrow"></mat-icon>

        <div class="order-content">
          <div class="info">
            <div class="heading-3 color-dark-blue" i18n="Order subheading">
              Pasūti darba izpildīšanu un saņem garantēto rezultātu! 🤓
            </div>

            <div class="stats">
              <div class="stat">
                <div class="body-2 color-dark-grey text-align-center" i18n="Order money stat title">Cena par darbu</div>
                <div class="box">
                  <ng-container i18n="Order money stat text start">no</ng-container>
                  <span>2</span>eur
                </div>
              </div>
              <div class="stat">
                <div class="body-2 color-dark-grey text-align-center" i18n="Order time stat title">Izpildīšanas laiks</div>
                <div class="box">
                  <ng-container i18n="Order time stat text start">no</ng-container>
                  <span>1</span>
                  <ng-container i18n="Order time stat text end">stundas</ng-container>
                </div>
              </div>
            </div>

            <div class="factors">
              <div class="heading-4 color-dark-blue" i18n="Order factors title">
                Atstājot pieteikumu, lūdzam norādīt sekojošas detaļas:
              </div>
              <div class="list">
                <div class="item" *ngFor="let factor of factors">
                  <div class="icon">{{factor.icon}}</div>
                  <div class="body-1 color-dark-grey">{{factor.name}}</div>
                </div>
              </div>
            </div>
          </div>

          <div class="order-form">
            <order-form></order-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="section-wrapper">
  <div class="section-content">

    <div class="contacts-line-wrapper">
      <div class="info">
        <div class="icon">🙋‍️</div>
        <div>
          <div class="title">Palika jautājumi?</div>
          <div class="subtitle">Sazinieties ar mums jebkurā laikā!</div>
        </div>
      </div>
      <div class="contacts">
        <div class="contact"><mat-icon>email</mat-icon><a href="mailto:info@eduhelp.lv">info&#64;eduhelp.lv</a></div>
        <div class="contact"><mat-icon>local_phone</mat-icon><a href="tel:+371 26936857">+371 26936857</a></div>
      </div>

    </div>

  </div>
</div>


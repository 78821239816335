<div class="section-wrapper bg-color-dark-blue upper">
  <div class="section-content">
    <div class="upper-footer-wrapper">

        <img class="logo" [src]="logoPath + logoName">

        <div class="heading-4 color-white" i18n="Footer heading contacts">Kontakti</div>

        <div class="main">
          <div class="body-3 color-extra-light-grey" i18n="Footer body about">
            Eduhelp eksperti veic darbu pie materiāla atlases, apstrādes un strukturēšanas
            atbilstoši klienta prasībām. Šī darba rezultāts nav gatavs zinātnisks darbs, bet var kalpot kā
            avots tā rakstīšanai.
          </div>

        </div>

        <div class="contacts column">
          <div class="body-3 color-extra-light-grey">
            <ng-container i18n="Telephone number">Tel. Nr.:</ng-container>
            &nbsp;
            <a class="link" href="tel:+371 26936857">+371 26936857</a></div>
          <div class="body-3 color-extra-light-grey">Email: &nbsp;<a class="link" href="mailto:info@eduhelp.lv">info&#64;eduhelp.lv</a></div>

          <div class="social-icons">

            <a href="https://www.instagram.com/eduhelp_lv">
              <mat-icon class="social-icon" svgIcon="edu:ic-ig-coloured"></mat-icon>
            </a>

            <a href="https://wa.me/37126936857">
              <mat-icon class="social-icon" svgIcon="edu:ic-wp-coloured"></mat-icon>
            </a>

            <a href="https://t.me/eduhelplv">
              <mat-icon class="social-icon" svgIcon="edu:ic-tg-coloured"></mat-icon>
            </a>

          </div>

        </div>
      </div>

    </div>
</div>
<div class="section-wrapper bg-color-dark-blue lower">
  <div class="section-content">
    <div class="lower-footer-wrapper">
      <div class="body-3 color-white">
        © 2018-2023, Eduhelp.
        <a class="link" target="_blank" [routerLink]="'terms'" i18n="Terms of use@@terms">
          Privātuma politika
        </a>
      </div>
    </div>
  </div>
</div>


<div class="section-wrapper bg-color-dark-blue">
  <div class="section-content">
    <div class="info-wrapper">
<!--      <span class="body-3 color-white">-->
<!--        🔥️ 100% atlaide jebkuram pasūtījumam līdz 04.03.2022 🔥-->
<!--      </span>-->
      <span class="body-3 color-white" i18n="Info block title">
        🔥 Semestra beigu piedāvājums: 70% atlaide pasūtījumam līdz 18.12. 🔥
      </span>
      <span *ngIf="time" class="body-3 color-white">
        <ng-container i18n="Info block after days">Piedāvājums beigsies pēc {{time.days}} dienām</ng-container>
        {{time.hours >= 10 ? '' : 0}}{{time.hours}}:{{time.minutes >= 10 ? '' : 0}}{{time.minutes}}:{{time.seconds >= 10 ? '' : 0}}{{time.seconds}}
    </span>

<!--      <span class="body-3 color-white" i18n="Info block body">-->
<!--        📅 Pasteidzies visu nokārtot! 📅 Mācību gada beigu piedāvājums - 20% atlaide pirmajam pasūtījumam 🎁-->
<!--      </span>-->
<!--      <span class="body-3 color-white" i18n="Info block body">-->
<!--        📅 Pasteidzies visu nokārtot! 📅-->
<!--        </span>-->
<!--      <span>-->
<!--        Mācību gada beigu piedāvājums - 20% atlaide pirmajam pasūtījumam 🎁-->
<!--      </span>-->
    </div>
  </div>
</div>

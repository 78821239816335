<div class="section-wrapper bg-color-light-blue">
  <div class="section-content">
    <div
      class="heading-2 color-dark-blue text-align-center"
      i18n="Advantages heading@@advantagesHeading"
    >Kāpēc tieši mēs
    </div>
    <div class="advantages-list">
      <ng-container *ngFor="let advantage of advantages; let i = index">
        <div class="advantage-box">
          <mat-icon fontSet="material-icons-round">{{advantage.icon}}</mat-icon>
          <div class="info">
            <div class="heading-4 color-dark-blue">{{advantage.title}}</div>
            <div class="body-2 color-dark-grey">{{advantage.text}}</div>
          </div>
        </div>
        <div *ngIf="i === 2" class="advantage-box image">
          <mat-icon class="illustration" svgIcon="edu:ill-experts"></mat-icon>
          <div class="heading-3 color-dark-blue">50+</div>
          <div
            class="heading-4 color-dark-blue"
            i18n="Advantages experts heading"
          >Eksperti ir gatavi palīdzēt tev katru dienu!
          </div>
          <mat-icon class="blob" svgIcon="edu:ic-blob"></mat-icon>
        </div>
      </ng-container>

    </div>
  </div>
</div>

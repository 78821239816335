<div class="notification-wrapper">
  <mat-dialog-content>

    <ng-container *ngIf="data.status === emailStatus.SUCCESS">
      <mat-icon class="success">check_circle_outline</mat-icon>
      <div class="info">
        <div class="heading-3 color-dark-blue" i18n="Email notification success heading">Paldies!</div>
        <div class="body-2 color-dark-grey">
          <ng-container *ngIf="data.type === emailType.ORDER || data.type === emailType.TUTORING" i18n="Email notification success order body">
            Jūsu pieteikums ir veiksmīgi nosūtīts.
          </ng-container>
          <ng-container *ngIf="data.type === emailType.QUESTION" i18n="Email notification success question body">
            Jūsu jautājums ir veiksmīgi nosūtīts.
          </ng-container>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="data.status === emailStatus.ERROR">
      <mat-icon class="error">error_outline</mat-icon>
      <div class="info">
        <div class="heading-3 color-dark-blue" i18n="Email notification error heading">Oops...</div>
        <div class="body-2 color-dark-grey" i18n="Email notification error body">Notika servera kļūda. Mēģini vēlreiz.<br>Vai sazinies ar mums uz tiešo:</div>
      </div>
      <div class="contacts">
        <div class="contact"><mat-icon>email</mat-icon><a class="heading-4 link-2" href="mailto:info@eduhelp.lv">info&#64;eduhelp.lv</a></div>
        <div class="contact"><mat-icon>local_phone</mat-icon><a class="heading-4 link-2" href="tel:+371 26936857">+371 26936857</a></div>
      </div>
    </ng-container>

  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close i18n="Email notification close button title">Aizvert</button>
  </mat-dialog-actions>
</div>

<div class="section-wrapper bg-color-dark-blue">
  <div class="section-content">

    <div class="social-medias-wrapper">
      <div class="info">
        <div class="heading-2 color-white" i18n="Social medias heading">Mūsu sociālie tīkli</div>
        <div class="body-1 color-extra-light-grey" i18n="Social medias body">
          Seko mums mūsu sociālajos tīklos, lai pēc iespējas ātrāk uzzinātu par Eduhelp jaunumiem un iespējamām
          akcijām. Kā arī vari pasūtīt darba izpildīšanu rakstot mums jebkurā no mesendžēriem 😉
        </div>
        <div class="buttons">
<!--          <a mat-flat-button color="primary" class="round" href="https://www.facebook.com/eduhelp.lv">-->
<!--            <mat-icon svgIcon="edu:ic-fb"></mat-icon>-->
<!--          </a>-->
          <a mat-flat-button color="primary" class="round" href="https://www.instagram.com/eduhelp_lv">
            <mat-icon svgIcon="edu:ic-ig"></mat-icon>
          </a>
          <a mat-flat-button color="primary" class="round" href="https://www.tiktok.com/@eduhelp_lv">
            <mat-icon svgIcon="edu:ic-tt"></mat-icon>
          </a>
        </div>
      </div>

      <div class="phone">
        <img class="first"
             [@fade]="state"
             (@fade.done)="onFade()"
             [src]="images[frontIndex].src">
        <img [src]="images[backIndex].src">
      </div>

    </div>
  </div>
</div>

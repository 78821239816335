<div class="section-wrapper bg-color-white">
  <div class="section-content">

    <div
      class="heading-2 color-dark-blue text-align-center"
      i18n="Work heading"
    >
      Kā mēs strādājam
    </div>
    <div
      class="body-1 color-dark-grey text-align-center"
      i18n="Work body"
    >
      Lai saņemtu gatavu darbu, nepieciešams izpildīt 5 vienkāršus soļus 👨‍🔧
    </div>

    <div class="work-content">
      <div class="left-section">
        <mat-icon svgIcon="edu:ill-work-together"></mat-icon>
      </div>
      <div class="right-section">
        <div class="steps-list">
          <div *ngFor="let step of steps; let i = index; let isLast = last" class="step-box" [class.last]="isLast">
            <div class="step-number">{{i + 1}}</div>
            <div>
              <div class="heading-4 color-dark-blue">{{step.title}}</div>
              <div class="body-2 color-dark-grey" [innerHTML]="step.text"></div>
            </div>
            <div class="line"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="page-wrapper">
  <div class="section-wrapper">
    <div class="section-content not-found-wrapper">

      <mat-icon>error_outline</mat-icon>
      <div class="heading-2 color-dark-blue" i18n="Not found heading">Lapa nav atrasta - 404</div>
      <div class="body-1 color-dark-grey" i18n="Not found body">️Atvainojamies, Jūsu meklētās lapas nav vai tā ir pārcelta citā vietā.</div>

      <a mat-flat-button color="primary" [routerLink]="''">
        <ng-container i18n="Go to main page button title@@mainPageButtonTitle">Uz galveno</ng-container>
      </a>
    </div>
  </div>
</div>

<div class="client-form-wrapper">

  <div class="heading-3 color-dark-blue text-align-center" i18n="Order form heading">Atstāj pieteikumu</div>
  <form #ngForm="ngForm" [formGroup]="orderForm" (ngSubmit)="onFormSubmit()" class="client-form mt-20">
    <mat-form-field appearance="outline">
      <mat-label i18n="@@formFieldFullNameLabel">Pilnais vārds</mat-label>
      <input matInput
             placeholder="Andris Liepa"
             required
             formControlName="name">
      <mat-error *ngIf="orderForm.controls.name.invalid">{{getNameErrorMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label i18n="Form field telephone label@@formFieldTelephoneLabel">Telefona numurs</mat-label>
      <input matInput
             required
             placeholder="22222222"
             type="number"
             formControlName="number">
      <mat-error *ngIf="orderForm.controls.number.invalid">{{getNumberErrorMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Email</mat-label>
      <input matInput
             placeholder="name@example.com"
             formControlName="email">
      <mat-error *ngIf="orderForm.controls.email.invalid">{{getEmailErrorMessage}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label i18n="@@formFieldWorkDescLabel">Darba apraksts</mat-label>
      <textarea matInput
                required
                maxlength="256"
                [placeholder]="workDescriptionPlaceholder"
                formControlName="description"></textarea>
      <mat-hint align="end">{{orderForm.controls.description.value?.length}} / 256</mat-hint>
      <mat-error *ngIf="orderForm.controls.description.invalid">{{getDescErrorMessage}}</mat-error>

    </mat-form-field>

    <file-attachment formControlName="files"></file-attachment>

    <button mat-flat-button
            color="primary">
      <mat-spinner *ngIf="busy" [strokeWidth]="3" [diameter]="36"></mat-spinner>
      <ng-container *ngIf="!busy">
        <span i18n="@@sendFormButtonTitle">Nosūtīt</span>
        <span class="ml-10">&#8594;</span>
      </ng-container>
    </button>

    <div class="body-3 color-dark-grey text-align-center mt-20">
      <ng-container i18n="Agree with terms text@@agreeWithTermsText">
        ❗️Nosūtot pieteikumu, tu apliecini, ka esi piekritis
      </ng-container>
      <a class="link" target="_blank" routerLink="../terms" i18n="Agree with terms link@@agreeWithTermsLink">Privātuma politikai</a>.
    </div>
  </form>

</div>
